import React from "react"
import ReactHtmlParser from 'react-html-parser';
import { Layout, SEO } from "../../components";
import { validateArray } from "../../components/utilities/functions"
import styles from "./index.module.scss";

const IndexPage = props => {
	const { pageContext } = props
	const {
		uid = "",
		locale = "",
		url,
		seo = {},
		heading,
		description,
		products_title,
		products,
		hide_footer_next_project_section = false
	} = pageContext.node || {}

	seo.title = (seo && seo.title) || heading

	const extension = {
		contenttype: pageContext?.contentType || "",
		uid: uid,
		locale: locale,
	}

	return (
		<Layout
			hideFooterNextSection={hide_footer_next_project_section}
			pageURL={url}
			className="subprocessors-page"
		>
			<SEO
				pageURL={url} {...seo}
				meta={seo?.no_index ?
					[{ "name": 'robots', content: "noindex, nofollow" }] : null}
				extension={extension}
			/>
			<div className={"container container--980 " + styles.container}>
				<div >
					{heading && <h1 className={styles.heading}>{heading}</h1>}
					{description && <div className={styles.description}>{ReactHtmlParser(description)}</div>}
				</div>
				<div className={styles.products_wrapper}>
					{products_title && <h3 className={styles.products_title}>{products_title}</h3>}

					{
						!!products?.length &&
						<ul className={styles.productList}>
							{
								products?.map((item, index) => (
									<li key={item.title + index}>
										{
											item?.link ?
												<a className="link"
													target={(item.external ? "_blank" : "_self")}
													rel={(item.external ? "noopener noreferrer" : "")}
													href={item?.link}>
													{item?.title}
													{item?.description &&
														<div>
															{ReactHtmlParser(item?.description)}
														</div>
													}
												</a>
												: <>
													{item?.title}
													{item?.description &&
														<div>
															{ReactHtmlParser(item?.description)}
														</div>
													}
												</>
										}
									</li>
								))
							}
						</ul>
					}

				</div>
			</div>
		</Layout>
	)
}

export default IndexPage
